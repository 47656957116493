require("lib/debug-console").default({
    logLimit: 500,
    isPretty: true,
    visible: false,
    logLevel: { debug: false },
});

const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
const ratio = vw / vh;

if ((ratio >= 1.6 && ratio <= 2) || process.env.DISABLE_MOBILE_MODE) {
    import("./game");
} else {
    import("./mobile");
}
