export default ({
    timeout = null,
    logLimit = 100,
    isPretty = false,
    visible = true,
    logLevel = {},
    // eslint-disable-next-line sonarjs/cognitive-complexity
} = {}) => {
    if (window && window.console && window.console.destroyDebugConsole instanceof Function) {
        window.console.destroyDebugConsole();
    }

    const levels = {
        info: true,
        warn: true,
        error: true,
        exception: true,
        debug: true,
        ...logLevel,
    };

    const debugConsole = document.createElement("div", { id: "debug-console" });
    Object.entries({
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        // "max-height": "30%",
        "font-size": "0.75rem",
        "z-index": 100500,
        display: visible ? "" : "none",
    }).forEach(([key, value]) => {
        debugConsole.style[key] = value;
    });
    document.body.appendChild(debugConsole);
    const removeDebugConsoleElement = () => document.body.removeChild(debugConsole);

    window.console.recordsDebugConsole = [];
    // eslint-disable-next-line no-unused-vars
    const addRecord = (level, params, fn = null) => {
        if (level in levels && !levels[level]) {
            return;
        }

        const record = {
            level,
            params,
            eventAt: new Date(),
            text: params
                .map((item) =>
                    typeof item === "object"
                        ? JSON.stringify(item, null, isPretty ? 2 : null)
                        : item,
                )
                .join(" "),
        };
        if (window && window.console && Array.isArray(window.console.recordsDebugConsole)) {
            window.console.recordsDebugConsole.push(record);
            while (window.console.recordsDebugConsole.length > logLimit && logLimit > 0) {
                window.console.recordsDebugConsole.shift();
            }
        }

        const element = document.createElement("pre");
        element["white-space"] = "pre-wrap";
        element.style.color =
            {
                error: "red",
                exception: "red",
                warn: "yellow",
                debug: "green",
            }[level] || "white";
        element.style.backgroundColor = { error: "#efefef94" }[level] || "transparent";
        element.innerText = [
            `[ ${record.level.toUpperCase()} ]`,
            record.eventAt.toISOString(),
            record.text,
        ].join(" ");

        debugConsole.appendChild(element);

        if (logLimit && logLimit > 0) {
            while (debugConsole.children.length > logLimit) {
                debugConsole.removeChild(debugConsole.firstChild);
            }
        }

        if (timeout) {
            setTimeout(() => debugConsole.removeChild(element), timeout);
        }

        if (fn instanceof Function) {
            fn(...params);
        }
    };

    const handlerErrorEvent = (event) => {
        const { error = {} } = event || {};
        if (error) {
            addRecord("error", [`${error.message}\n${error.stack}`], null);
        } else {
            addRecord("error", [`${event.message}`], null);
        }
    };
    window.addEventListener("error", handlerErrorEvent);

    const { log: $log, error: $error, warn: $warn, exception: $exception, debug: $debug } = console;
    window.console.log = (...params) => addRecord("info", params, $log);
    window.console.warn = (...params) => addRecord("warn", params, $warn);
    window.console.error = (...params) => addRecord("error", params, $error);
    window.console.debug = (...params) => addRecord("debug", params, $debug);
    window.console.exception = (...params) => addRecord("exception", params, $exception);

    window.console.isVisibleDebugConsole = { get: () => debugConsole.style.display !== "none" };
    window.console.showDebugConsole = () => {
        debugConsole.style.display = "";
    };
    window.console.hideDebugConsole = () => {
        debugConsole.style.display = "none";
    };

    window.console.destroyDebugConsole = () => {
        window.console.log = $log;
        window.console.warn = $warn;
        window.console.error = $error;
        window.console.debug = $debug;
        window.console.exception = $exception;

        delete window.console.destroyDebugConsole;
        delete window.console.showDebugConsole;
        delete window.console.hideDebugConsole;
        delete window.console.isVisibleDebugConsole;

        window.removeEventListener("error", handlerErrorEvent);

        removeDebugConsoleElement();
    };
};
